<template>
  <div class="yellow-Report-page">
    <PocCardTable>
      <div class="flexBetw">
        <div class="pcth-title-left">PC Tracker Mvp</div>
        <el-radio-group v-model="tabPosition" @change="tabClick">
          <el-radio-button label="dataList" role="dataShow"  
            >数据展示</el-radio-button
          >
          <el-radio-button label="dataUp" role="dataUp"  
            >数据上传</el-radio-button
          >
        </el-radio-group>
        <div style="width: 240px; display: flex; justify-content: end">
          <el-button
            :loading="queryLoading"
            role="goBack"
            @click="goBack"
             
            >返回</el-button
          >
        </div>
      </div>
      <template>
        <el-form class="flexRow" style="margin-top: 20px">
          <el-form-item label="solution Hub" label-position="top" >
            <el-select v-model="trackerForm.solutionHub" placeholder="请选择" @change="getList">
              <el-option
                v-for="(item, index) in solutionHubData"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="RF月份" label-position="top"  >
            <el-date-picker
              v-model="trackerForm.rfDate"
              type="month"
              placeholder="请选择月份"
              value-format="yyyy-MM"
              format="yyyy-MM"
              class="industry-selector"
              :clearable="false"
              @change="getList"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
    </PocCardTable>
    <PocCardTable v-show="tabPosition === 'dataUp'" style="margin-top: 20px">
      <div class="flexBetw">
        <div></div>
        <div></div>
        <div>
          <el-button
            :loading="queryLoading"
            role="goBack"
            :disabled="downStatus"
            @click="excelExport"
            >下载</el-button
          >
        </div>
      </div>
      <div class="upBoxContent">
        <div @click="openUpload">
          <div class="upBox">
            <div class="content pointer">
              <img class="addImg" src="@/assets/img/add.png" />
              <div>点击上传</div>
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in trackerList"
          :key="item.id"
          @click="sel(index)"
        >
          <div class="upBox" :class="{ upBoxHover: item.sel }">
            <div class="head flexBetw">
              <span>{{item.fileName}}</span>
              <div class="upBoxStatus" v-if="item.uploadFlag == 0">未上传</div>
            </div>
            <div class="contextBox">
              <div class="text">
                最近上传用户 <span>{{ item.uploadUser || '-' }}</span>
              </div>
              <div class="text">
                最近上传时间 <span>{{ item.uploadTime || '-' }}</span>
              </div>
              <div class="btn">
                <el-button
                  :loading="queryLoading"
                  @click="del(index)"
                  role="importEmpty"
                  >删除</el-button
                >
                <el-button
                  type="primary"
                  :loading="queryLoading"
                  role="importFile"
                  @click="importFile(item)"
                  >上传</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </PocCardTable>


      <!-- :solutionHubCom="trackerForm.solutionHub"
      :solutionHubDataCom="solutionHubData"
      :rfDateCom="trackerForm.rfDate" -->
    <fileUpComplex
      ref="fileUpComplex"
      
      :file-up-visible="fileUpVisible"
      :title="fileTitle"
      :filterConfig="filterConfigUp"
      :columnOptions="columnOptionsUpTable"
      @closeFileUp="closeFileUp"
    ></fileUpComplex>
  </div>
</template>

<script>
import fileUpComplex from '@/components/fileUp/fileUpComplex'
import {
  getPctrackerMvpList,
  getSolutionHub,
  delpctrackerMvp,
  pctracckerExport
} from '@/api/tranckerMvp'


import router from '@/router'
export default {
  components: {
    fileUpComplex
  },
  data() {
    return {
      tabPosition: 'dataUp',
      queryLoading: false,
      type: 'CHQ',
      //   上传组件
      fileUpVisible: false,
      filterConfigUp: {
        orgId: '',
        rfMonth: '',
        tableLikeId: ''
      },
      fileTitle: 'PC Tracker',
      columnOptionsUpTable: [],
      //   预览模块数据
      previewVisible: true,
      // 日期hub数据
      solutionHubData: [],
      trackerForm: {
        solutionHub: '',
        rfDate: ''
      },
      trackerList: [],
      downStatus:true,//下载按钮是否可以点击
    }
  },
  methods: {
    importFile(item){
      let obj = {
        uploadFlag:1,
        fileName:item.fileName,
        id:item.id,
        solutionHub:item.solutionHub,
        solutionHubData:this.solutionHubData,

        // !!!!!
        rfDate:item.rfDate.substring(0,7),//待确认是否修改为复现时间
      }
      this.$refs.fileUpComplex.setData(obj);
      this.fileUpVisible = true;
    },
    excelExport(){
      let ids = [];
      this.trackerList.map((item) => {
        if(item.sel) ids.push(item.id);
      })
    
      pctracckerExport(ids.toString());
    },
    del(index) {
      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          console.log('index', index)
          delpctrackerMvp({ id: this.trackerList[index].id }).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList();
            } else {
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    sel(index) {
      if (this.trackerList[index].uploadFlag == 0) return
      if (this.trackerList[index].sel) {
        this.$set(this.trackerList[index], 'sel', false)
      } else {
        this.$set(this.trackerList[index], 'sel', true)
      }

      this.downStatus = !this.trackerList.some(item => item.sel);
    },
    getSolutionHub() {
      getSolutionHub().then((res) => {
        if (res.code === 200) {
          this.solutionHubData = res.data
          this.trackerForm.solutionHub = res.data[0];
          this.getList()

        }
      })
    },
    getList() {
      this.downStatus = true;
      let data = {
        rfDate:this.trackerForm.rfDate + '-1',
        solutionHub:this.trackerForm.solutionHub,
      }
      getPctrackerMvpList(data).then((res) => {
        if (res.code === 200) {
          this.trackerList = res.data
        }
      })
    },
    openUpload() {
      let obj = {
        uploadFlag:0,
        ...this.trackerForm,
        solutionHubData:this.solutionHubData
      }
      this.$refs.fileUpComplex.setData(obj);
      this.fileUpVisible = true;
    },
    tabClick(e) {
      console.log(e)
    },
    goBack() {
      router.go(-1)
    },
    getTabList() {},
    closeFileUp() {
      this.fileUpVisible = false
      // this.getTabList()
      this.getList()
    }
    // 预览
    // closePerview(){
    //     this.previewVisible = false
    // }
  },
  created() {
    this.getSolutionHub()
    

  },
  mounted() {
    let data = new Date()
    this.trackerForm.rfDate = data.getFullYear() + '-' + (data.getMonth() + 1)
  }
}
</script>
<style lang="scss" scoped>

.el-input {
  width: 220px;
}
.yellow-Report-page {
  ::v-deep .el-table__cell {
    border-bottom: 0;
    border-right: 0;
  }
  ::v-deep .table-header {
    border-right: 4px solid #ffffff;
  }
  ::v-deep .rf-header2 {
    border-right: 0 solid #ebeef5;
  }
  ::v-deep .rf-header2:after {
    content: '';
    width: 1px;
    height: 20px;
    position: relative;
    top: 0;
    left: -1px;
    border-left: 2px solid #ffffff;
  }
  ::v-deep .rf-header2:last-child:after {
    content: '';
    width: 0;
    height: 0;
    position: relative;
    top: 0;
    left: -1px;
    border-left: 2px solid #ffffff;
  }
  .totalRaw {
    span {
      font-weight: 800;
    }
  }
  ::v-deep .bodyTable {
    .el-card__body {
      padding: 20px 0 20px 20px;
      .tableTabs {
        margin-right: 20px;
      }
      .tableTopBox {
        margin-right: 20px;
      }
      .customTable {
        margin-right: 10px;
        .poc-table {
          margin-bottom: 20px;
        }
        .poc-table:last-child {
          margin-bottom: 0;
        }
      }
      .defaultTable {
        margin-right: 20px;
      }
    }
  }
  .tableTopBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    .activeName {
      width: 100%;
      text-align: center;
      line-height: 30px;
      span {
        color: #303133;
        font-size: 16px;
        font-weight: normal;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        font-family: Roboto-Bold, Roboto, system-ui;
      }
      img {
        margin-left: 8px;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    }
  }
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  .el-form-item {
    margin: 0 24px 0 0;
  }
  .scopeBox {
    overflow-x: auto;
  }
  .scopeItem {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 16px;
    padding: 0 5px;
  }
  .active {
    color: #303133;
    text-align: center;
  }
  .scopeContainer {
    margin-top: 23px;
  }
  .guidaoactive {
    padding-top: 14px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
  }
  .guidao {
    font-size: 0;
    width: 100%;
    padding: 0 0 12px 0;
    box-sizing: border-box;
    overflow: hidden;
    .guidaoImg {
      overflow: hidden;
    }
  }
  .upBoxContent {
    display: grid;
    height: calc(88vh - 60px - 20px - 110px);
    overflow-x: scroll;
    grid-template-columns: repeat(auto-fill, 248px);
    grid-template-rows: repeat(auto-fill, 143px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-top:20px;
  }
  .upBox {
    width: 248px;
    height: 143px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    align-items: center;
    margin-right: 24px;
    margin-bottom: 24px;
    .head {
      height: 34px;
      align-items: center;
      border-bottom: 1px solid #ebeef5;
      padding: 0 12px;
      span {
        font-weight: bold;
        font-family: Roboto-Bold, Roboto;
        color: #4e4e50;
        line-height: 16px;
        height: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .upBoxStatus {
        width: 52px;
        height: 18px;
        background-color: #f4f4f4;
        color: #909399;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        border-radius: 2px;
      }
    }
    .noUp {
      width: 52px;
      height: 18px;
      background: #f4f4f4;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
      color: #909399;
      line-height: 17px;
      text-align: center;
    }
    .content {
      width: 248px;
      height: 143px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // width: 56px;
      font-size: 14px;
      // margin: 20px auto;
      .addImg {
        width: 24px;
        height: 24px;
        margin-bottom: 8px;
      }
    }
    .contextBox {
      font-weight: 400;
      color: #909399;
      font-size: 12px;
      padding: 10px 12px;
      .text {
        margin-bottom: 8px;
        span {
          margin-left: 12px;
          font-weight: 500;
          color: #545454;
          font-size: 14px;
        }
      }
      .btn {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
  .upBoxHover {
    border: 1px solid #ba9765;
  }
  .el-button {
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
    margin-left: 12px;
  }
  .el-card .el-card__header,
  .el-card .el-card__body {
    padding: 20px;
  }
  ::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2),
  .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
    // padding: 0 10px !important;
    cursor: pointer;
    border: none;
  }
  ::v-deep .el-tabs__header {
    margin: 0 0 8px;
  }
  ::v-deep .el-tabs__nav-wrap.is-scrollable {
    padding: 0 10px;
  }
  ::v-deep .el-tabs__nav-prev {
    top: -10px;
  }
  ::v-deep .el-tabs__nav-next {
    top: -10px;
  }
  ::v-deep .el-tabs__item {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    .is-active {
      color: #4e4e50;
      font-weight: 400;
    }
  }
  ::v-deep .el-tabs__item.is-active {
    color: #4e4e50;
    font-weight: 500;
  }
}
</style>
