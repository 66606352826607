const op111Column = [
  {
    key: 'hcNature',
    name: 'HC Nature',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    // // fixed: true, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    // // fixed: true,
    width: 180
  },
  {
    key: 'bu',
    name: 'BU',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'location',
    name: 'Local',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'function',
    name: 'Function',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'team',
    name: 'Team',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'staffNo',
    name: 'Staff No.',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'position',
    name: 'Position',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'grading',
    name: 'Grading',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'client',
    name: 'Client',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'localExpat',
    name: 'Local/Expat',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: ' nameCn',
    name: 'Name (Chinese)',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'nameEn',
    name: 'Name (English)',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'startDate',
    name: 'Start Date',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'leavingDate',
    name: 'Leaving Date',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'offerDate',
    name: 'Offer Date',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'exJobHolderName',
    name: 'Ex-job holder Name',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'leavingDateExJobHolder',
    name: 'Leaving Date (ex-job holder)',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'salaryOfMonth',
    name: 'Salary of Month',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'allowancePerMonth',
    name: 'Allowance per month',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'lifeInsurancePerMonth',
    name: 'Life Insurance/per month',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'medicalInsurancePerMonth',
    name: 'Medical  Insurance/per month',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'commercialTaxPerMonthByCompany',
    name: 'commercial tax/per month-borned by company',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'thirteenSalary',
    name: '13th Payroll',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'jan',
    name: 'HC Jan-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'feb',
    name: 'HC Feb-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'mar',
    name: 'HC Mar-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'apr',
    name: 'HC Apr-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'may',
    name: 'HC May-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'jun',
    name: 'HC Jun-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'jul',
    name: 'HC Jul-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'aug',
    name: 'HC Aug-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'sep',
    name: 'HC Sep-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'oct',
    name: 'HC Oct-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'nov',
    name: 'HC Nov-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'decHc',
    name: 'HC Dec-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalJan',
    name: 'Total Cost Jan-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalFeb',
    name: 'Total Cost Feb-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalMar',
    name: 'Total Cost Mar-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalApr',
    name: 'Total Cost Apr-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalMay',
    name: 'Total Cost May-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalJun',
    name: 'Total Cost Jun-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalJul',
    name: 'Total Cost Jul-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalAug',
    name: 'Total Cost Aug-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalSep',
    name: 'Total Cost Sep-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalOct',
    name: 'Total Cost Oct-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalNov',
    name: 'Total Cost Nov-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  },
  {
    key: 'totalDec',
    name: 'Total Cost Dec-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // // fixed: true,
  }
]
const op151Column = [
  {
    key: 'bu',
    name: 'BU',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    // fixed: true, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'department',
    name: 'Department ',
    dataType: 'string',
    visible: true,
    sortable: false,
    // fixed: true,
    width: 180
  },
  {
    key: 'nameEn',
    name: 'Name (English)',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'nameCn',
    name: 'Name (Chinese)',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'position',
    name: 'Position',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'client',
    name: 'Client',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'jan',
    name: 'Jan-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'feb',
    name: 'Feb-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'mar',
    name: 'Mar-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'apr',
    name: 'Apr-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'may',
    name: 'May-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'jun',
    name: 'Jun-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'jul',
    name: 'Jul-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'aug',
    name: 'Aug-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'sep',
    name: 'Sep-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'oct',
    name: 'Oct-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'nov',
    name: 'Nov-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'decCost',
    name: 'Dec-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'salaryFullYear',
    name: 'Full Year',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  }
]
const op152Column = [
  {
    key: 'bu',
    name: 'BU',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    // fixed: true, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'jan',
    name: 'Jan-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'feb',
    name: 'Feb-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'mar',
    name: 'Mar-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'apr',
    name: 'Apr-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'may',
    name: 'May-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'jun',
    name: 'Jun-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'jul',
    name: 'Jul-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'aug',
    name: 'Aug-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'sep',
    name: 'Sep-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'oct',
    name: 'Oct-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'nov',
    name: 'Nov-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'decCost',
    name: 'Dec-21',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  },
  {
    key: 'salaryFullYear',
    name: 'Full Year',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  }
]
const pcPlugColumn = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    // fixed: true, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'bu',
    name: 'BU ',
    dataType: 'string',
    visible: true,
    sortable: false,
    // fixed: true,
    width: 180
  },
  {
    key: 'pcPlug',
    name: 'PC Plug',
    dataType: 'string',
    visible: true,
    sortable: false
    // fixed: true,
  }
]
export { op111Column, op151Column, op152Column, pcPlugColumn }
