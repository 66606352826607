import request from '@/utils/request'
import store from '@/store'
import { exportDownload } from '@/api/export'
import $http, { $httpOrigin } from '@/utils/request'

// 获取pctrackerMvp面板数据
export const getPctrackerMvpList = (data) => {
    return request({
      url: '/pctrackerMvp/pctrackerMvpInfo',
      method: 'get',
      params: {
        ...data
      }
    })
  }
	// 获取solutionHubInfo数据
  export const getSolutionHub = () => {
    return request({
      url: '/pctrackerMvp/solutionHub',
      method: 'get'
    })
  }
  // 上传文件
	export const importExcel = (data, uploadEvent) => {
	  return request({
	    url: '/pctrackerMvp/importExcel',
	    method: 'POST',
      data,
      uploadEvent
	  })
	}
  // 删除pctrackerMvp版本
  export const delpctrackerMvp = (data) => {
    return request({
      url: '/pctrackerMvp/del',
      method: 'get',
      params: {
        ...data
      }
    })
  }
    // 删除pctrackerMvp版本
    export const addVersion = (data) => {
      return request({
        url: '/pctrackerMvp/addVersion',
        method: 'get',
        params: {
          ...data
        }
      })
    }
    // 上传excal
  	export const uploadExcel = (data) => {
      return request({
        url: '/pctrackerMvp/upload',
        method: 'POST',
        data,
      })
    }

        // 上传excal
  	export const uploadData = (data) => {
      return request({
        url: '/pctrackerMvp/uploadData',
        method: 'POST',
        data,
      })
    }
    const url = process.env['VUE_APP_BASE_API']
    // // 导出
    export const pctracckerExport = (data) => {
      window.location = `${url}/api/iris/pctrackerMvp/downloadPackage?ids=${data}&token=${store.getters.token}`
    }

    // 下载
    // export const pctracckerExport = (data) => {
    //   return request({
    //     url: '/pctrackerMvp/downloadPackage',
    //     method: 'get',
    //     params: {
    //       ...data
    //     }
    //   })
    // }