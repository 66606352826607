<template>
  <el-dialog
    :title="title"
    :visible.sync="fileUpVisible"
    width="50%"
    :destroy-on-close="true"
    :before-close="handleClose"
    class="fileUpComplexPage"
  >
    <el-steps
      :active="active"
      finish-status="success"
      space="280px"
      :align-center="true"
      process-status="error"
    >
      <el-step
        title="新建/上传"
        :class="{ active: active === 0, upError: upError, xtk: active === 3 }"
        :status="stepStatus['1']"
      >
        <i class="step" slot="icon">1</i>
      </el-step>
      <el-step
        title="数据预览"
        :class="{ active: active === 1, xtk: active === 3 }"
        :status="stepStatus['2']"
      >
        <i class="step" slot="icon">2</i>
      </el-step>
      <el-step
        title="导入成功"
        :class="{ active: active === 2, xtk: active === 3 }"
        :status="stepStatus['3']"
      >
        <i class="step" slot="icon">3</i>
      </el-step>
    </el-steps>

    <div v-if="active === 0">
      <div class="topTitle">基础信息</div>
      <el-form
        class="flexRow"
        style="margin-top: 10px"
        :model="trackerForm"
        :rules="rules"
        ref="formRules"
      >
        <el-form-item label="文件名称" label-position="top" prop="fileName">
          <el-input
            v-model="trackerForm.fileName"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="solution Hub" label-position="top">
          <el-select
            :value="trackerForm.solutionHub"
            placeholder="请选择"
            @change="trackerChange"
            disabled
          >
            <el-option
              v-for="item in solutionHubData"
              :key="item.value"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="RF月份" label-position="top">
          <el-date-picker
            v-model="trackerForm.rfDate"
            type="month"
            placeholder="请选择月份"
            value-format="yyyy-MM"
            format="yyyy-MM"
            class="industry-selector"
            :clearable="false"
            disabled
          >
            <!-- @input="timeChange" -->
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="topTitle">报表</div>
      <el-upload
        class="uploadBox"
        ref="uploadBox"
        drag
        :on-change="uploadFileChange"
        action=""
        :show-file-list="false"
        :auto-upload="false"
      >
        <i class="el-icon-upload" style="width: 40px; height: 28px"></i>
        <div class="upload_text">
          点击此区域上传附件或将文件拖至此处
          <div class="upload_tip">仅支持 xlsx 格式，最大文件尺寸5MB</div>
        </div>
      </el-upload>
      <div class="progress">
        <div>
          <span class="fileName" v-if="upSuccess && fileInfo.fileName">
            {{ fileInfo.fileName }}
            <img
              class="img"
              src="@/assets/img/delImga.png"
              alt=""
              srcset=""
              @click="upDelInfo"
            />
          </span>
          <div v-if="upError && fileInfo.fileName">
            <span class="fileName fileNameRed">
              {{ fileInfo.fileName }}
              <img
                class="img"
                src="@/assets/img/delImga.png"
                alt=""
                srcset=""
                @click="upDelInfo"
              />
            </span>
            <div class="uptit">{{ upErrorMsg }}</div>
          </div>
        </div>

        <el-progress
          :percentage="loadProgress"
          v-if="progressFlag"
          color="#6EBAAF"
          :stroke-width="4"
          :show-text="false"
        ></el-progress>
      </div>
    </div>
    <div v-else-if="active === 1">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="OP111" name="first">
          <div class="fileInfo">
            文件名称 <span>{{ upFileName }}</span> 列数 <span>50</span> 行数
            <span>{{ op111Length }}</span
            >错误条数
            <span class="flagColor">{{ op111WrongLength }}</span>
          </div>
          <PocTable
            class="xtable"
            v-loading="queryLoading"
            :data="op111List"
            stripe
            hidepage
            width="100%"
            :max-height="maxHeight"
            ref="multipleTable"
            :header-cell-class-name="headerStyle"
            :row-class-name="tableAddClass"
          >
            <el-table-column
              v-for="item in op111Column"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :width="item.width"
              :min-width="item.width"
              class="elred"
            >

              <template slot-scope="scope">
                <el-tooltip effect="dark" placement="top" :disabled="scope.row.flag=='0'">
                  <div v-html="scope.row.reason" slot="content"></div>
                  <div class="tooltip" v-if="scope.row[item.key]">{{scope.row[item.key]}}</div>
                  <div class="tooltip" v-else  style="opacity:0;">显示</div>
                </el-tooltip>
              </template>
            </el-table-column>

          </PocTable>
        </el-tab-pane>
        <el-tab-pane label="OP151" name="second"
          ><div class="fileInfo">
            文件名称 <span>{{ upFileName }}</span> 列数 <span>18</span> 行数
            <span>{{ op151Length }}</span
            >错误条数
            <span class="flagColor">{{ op151WrongLength }}</span>
          </div>
          <PocTable
            v-loading="queryLoading"
            :data="op151List"
            stripe
            hidepage
            width="100%"
            :max-height="maxHeight"
            ref="multipleTable"
            :header-cell-class-name="headerStyle"
          >
            <el-table-column
              v-for="item in op151Column"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :width="item.width"
              :min-width="item.width"
              class="elred"
            >
            <template slot-scope="scope">
                <el-tooltip effect="dark" placement="top" :disabled="scope.row.wrongFlag=='0'">
                  <div v-html="scope.row.reason" slot="content"></div>
                  <div class="tooltip">{{scope.row[item.key]}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
          </PocTable>
        </el-tab-pane>
        <el-tab-pane label="OP152" name="third"
          ><div class="fileInfo">
            文件名称 <span>{{ upFileName }}</span> 列数 <span>18</span> 行数
            <span>{{ op152Length }}</span
            >错误条数
            <span class="flagColor">{{ op152WrongLength }}</span>
          </div>
          <PocTable
            v-loading="queryLoading"
            :data="op152List"
            stripe
            hidepage
            width="100%"
            :max-height="maxHeight"
            ref="multipleTable"
            :header-cell-class-name="headerStyle"
          >
            <el-table-column
              v-for="item in op152Column"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :width="item.width"
              :min-width="item.width"
              class="elred"
            >
            <template slot-scope="scope">
                <el-tooltip effect="dark" placement="top" :disabled="scope.row.wrongFlag=='0'">
                  <div v-html="scope.row.reason" slot="content"></div>
                  <div class="tooltip">{{scope.row[item.key]}}</div>
                </el-tooltip>
              </template>
            </el-table-column> </PocTable
        ></el-tab-pane>
        <el-tab-pane label="PC Plug" name="fourth"
          ><div class="fileInfo">
            文件名称 <span>{{ upFileName }}</span> 列数 <span>3</span> 行数
            <span>{{ pcPlugLength }}</span
            >错误条数
            <span class="flagColor">{{ pcPlugWrongLength }}</span>
          </div>
          <PocTable
            v-loading="queryLoading"
            :data="pcPlugList"
            stripe
            hidepage
            width="100%"
            :max-height="maxHeight"
            ref="multipleTable"
            :header-cell-class-name="headerStyle"
          >
            <el-table-column
              v-for="item in pcPlugColumn"
              :key="item.key"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :width="item.width"
              :min-width="item.width"
              class="elred"
            >
            <template slot-scope="scope">
                <el-tooltip effect="dark" placement="top" :disabled="scope.row.flag=='0'">
                  <div v-html="scope.row.reason" slot="content"></div>
                  <div class="tooltip">{{scope.row[item.key]}}</div>
                </el-tooltip>
              </template>
            </el-table-column> </PocTable
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div v-else class="upSuccessBox">
      <img src="@/assets/img/upFileSuccess.png" />
      <div>上传成功</div>
      <span>成功导入{{ fileInfo.rows }}条数据</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="active === 0" @click="handleClose">取消</el-button>
      <el-button v-if="active === 0" @click="temporary">暂存信息</el-button>
      <el-button
        v-if="active === 0"
        type="primary"
        :disabled="nextStatus"
        @click="next"
        >下一步</el-button
      >
      <el-button v-if="active === 1" @click="active = 0">上一步</el-button>
      <el-button
        v-if="active === 1"
        type="primary"
        @click="confirm"
        :loading="loading"
        :disabled="flag==1"
        >确认</el-button
      >
      <el-button v-if="active === 2" @click="handleClose">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
// new--
import {
  importExcel,
  addVersion,
  uploadData,
  uploadExcel
} from '@/api/tranckerMvp'
import {
  op111Column,
  op151Column,
  op152Column,
  pcPlugColumn
} from '@/views/pcTrackerMVP/TableColumn'
// newend---
import currency from 'currency.js'
const { $http } = $PCommon
const axios = $http.axios
export default {
  name: 'fileUpComplex',
  props: {
    fileUpVisible: {
      type: Boolean,
      require: false
    },
    title: {
      type: String,
      require: true
    },
    filterConfig: {
      type: Object,
      require: true
    },
    columnOptions: {
      type: Array,
      require: true
    }
    // new
    // rfDateCom: {
    //   type: String,
    //   require: true
    // },
    // solutionHubDataCom: {
    //   type: Array,
    //   require: true
    // },
    // solutionHubCom: {
    //   type: String,
    //   require: true
    // }
    // new
  },
  data() {
    return {
      // new
      op111Column: op111Column,
      op151Column: op151Column,
      op152Column: op152Column,
      pcPlugColumn: pcPlugColumn,
      uploadFlag: '', //0未上传1已上传
      trackerForm: {
        solutionHub: '',
        rfDate: '',
        fileName: ''
      },
      solutionHubData: [],
      op111Length: '',
      op111List: [],
      op151Length: '',
      op151List: [],
      op152Length: '',
      op152List: [],
      pcPlugLength: '',
      pcPlugList: [],
      upFileName: '',
      upId: '',
      fileSaveInfo: {}, //上传暂存
      nextStatus: true,
      loading: false, //确定上传按钮loading

      fileInfo: {
        fileName: '',
        columns: '',
        rows: ''
      },
      flag:1,
      // newend
      maxHeight: '300px',
      active: 0,
      queryLoading: false,
      tableData: [],
      reportImportId: '',

      // source: '',
      progressFlag: false,
      loadProgress: 0,
      upSuccess: false,
      upError: false,
      upErrorMsg: '*数据校验不合格上传失败',
      stepStatus: {
        // process当前步骤  wait 等待步骤 finish 完成步骤
        1: 'process',
        2: 'wait',
        3: 'wait'
      },
      // new-----
      // filterConfigUp: {
      //   fileName: '',
      //   rfMonth: '',
      //   solValue: ''
      // },
      type: '',
      activeName: 'first',
      rules: {
        fileName: [
          {
            required: true,
            message: '请输入文件名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    // 监听父组件的hub
    // solutionHubCom(newVal) {
    //   console.log('aa--1', newVal)
    //   this.trackerForm.solutionHub = newVal
    // },
    // rfDateCom(newVal) {
    //   console.log('aa--2', newVal)
    //   this.trackerForm.rfDate = newVal
    // },
    active(newValue) {
      switch (newValue) {
        case 0:
          this.stepStatus = { 1: 'process', 2: 'wait', 3: 'wait' }
          break
        case 1:
          this.stepStatus = { 1: 'finish', 2: 'process', 3: 'wait' }
          break
        case 2:
          this.stepStatus = { 1: 'finish', 2: 'finish', 3: 'finish' }
          break
      }
    }
  },
  onMounted() {},
  mounted() {
    // new--
    // this.trackerForm.solutionHub = this.solutionHubCom;
    // this.trackerForm.rfDate = this.rfDateCom;
    // newend--
    // this.source = axios.CancelToken.source()
  },
  created() {
    console.log('columnConfig', this.columnConfig)
  },
  methods: {
    tableAddClass({ row }) {
      if (row.flag == 1) {
        return 'trRed'
      } else {
        return ''
      }
    },
    timeChange(e) {
      console.log('ee', e)
      this.trackerForm.rfDate = e
      this.$forceUpdate()
    },
    trackerChange(e) {
      this.trackerForm.solutionHub = e
      this.$forceUpdate()
      // this.$set(this.trackerForm,"solutionHub",e);//没管用
    },
    upDelInfo() {
      this.fileInfo.fileName = ''
      this.upErrorMsg = ''
      this.nextStatus = true
      this.upSuccess = false
      this.loadProgress = 0
    },
    setData(obj) {
      console.log('obj', obj)
      // if(!this.uploadFlag) this.trackerForm.fileName = "";
      this.uploadFlag = obj.uploadFlag
      if (obj.rfDate) this.trackerForm.rfDate = obj.rfDate
      if (obj.solutionHub) this.trackerForm.solutionHub = obj.solutionHub
      if (obj.solutionHubData) this.solutionHubData = obj.solutionHubData
      if (obj.fileName) this.trackerForm.fileName = obj.fileName
      if (obj.id) {
        this.upId = obj.id
      } else {
        this.upId = ''
      }
      if (obj.solutionHubData) this.solutionHubData = obj.solutionHubData
      this.fileSaveInfo = ''
    },
    // new--
    temporary() {
      this.$refs.formRules.validate((vaild) => {
        if (vaild) {
          let data = {
            fileName: this.trackerForm.fileName,
            id: this.upId,
            rfDate: this.trackerForm.rfDate + '-1',
            solutionHub: this.trackerForm.solutionHub
          }
          console.log('data', data)
          addVersion(data).then((res) => {
            console.log('res', res)
            if (res.code === 200) {
              this.$emit('closeFileUp', false)
              this.$message.success('暂存成功')
            }
          })
        }
      })
    },
    // newend
    handleClick() {},
    getTabList() {},
    currency(value) {
      return typeof value === 'number'
        ? currency(value, { symbol: '' }).format()
        : value
    },
    upExcel() {
      let fileData = new FormData()
      fileData.append('file', this.fileSaveInfo.raw)
      fileData.append('id', this.upId)
      uploadExcel(fileData).then((res) => {
        console.log('res', res)
        if (res.code === 200) {
          this.active = 2
          // this.$emit('closeFileUp', false)
          // this.$message.success('暂存成功')
          this.loading = false
        }
      })
    },
    uploadData() {
      let data = {
        fileName: this.trackerForm.fileName,
        id: this.upId,
        op111Length: this.op111Length,
        op111List: this.op111List,
        op151Length: this.op151Length,
        op151List: this.op151List,
        op152Length: this.op152Length,
        op152List: this.op152List,
        pcPlugLength: this.pcPlugLength,
        pcPlugList: this.pcPlugList
      }
      uploadData(data).then((res) => {
        console.log('res', res)
        if (res.code === 200) {
          // this.$emit('closeFileUp', false)
          // this.$message.success('暂存成功')
          this.loading = false
        }
      })
    },
    confirm() {
      this.loading = true
      this.upExcel()
      this.uploadData()
      // this.$Api.yellowReport
      //   .importConfirm({ reportImportId: this.reportImportId })
      //   .then(({ code, msg }) => {
      //     if (code === 200) {
      //       this.$message.success('上传成功')
      //     }
      //     this.loading = false
      //   })
      // this.active = 2
    },
    handleClose() {
      this.active = 0
      this.loadProgress = false
      this.trackerForm = {
        // solutionHub: '',
        // rfDate: '',
        fileName: ''
      }
      // this.reportImportId = ''
      // this.tableData = []
      this.upSuccess = false
      this.upError = false
      this.$emit('closeFileUp', false)
    },
    onChange(index) {
      // 父组件获取切换后的选中步骤
      console.log('current:', index)
    },
    // next() {
    //   // this.$message.info('请检查，上传文件大小不能超过5M')
    //   this.$refs.formRules.validate((vaild) => {
    //     if (vaild) {
    //       this.active = 1
    //     }
    //   })
    // },
    uploadFileChange(file) {
      console.log('file', file)
      this.fileSaveInfo = file
      this.fileInfo.fileName = file.name
      let fileType = file.name.substring(file.name.length - 4)
      if (fileType !== 'xlsx') {
        this.nextStatus = true
        this.upSuccess = false
        this.upError = true
        this.$message.error('仅支持 xlsx 格式')
        return
      } else {
        this.upError = false
        this.upSuccess = true

        this.nextStatus = false
      }
    },
    next() {
      this.upSuccess = false
      this.upError = false
      let file = this.fileSaveInfo
      if (!this.fileSaveInfo) return this.$message.error('请上传文件')
      this.$refs.formRules.validate((vaild) => {
        if (vaild) {
          let fileType = file.name.substring(file.name.length - 4)
          if (fileType !== 'xlsx') {
            this.$message.error('仅支持 xlsx 格式')
            return
          }
          this.fileInfo.fileName = file.name
          let fileData = new FormData()
          const isLt5M = file.size / 1024 / 1024 < 5
          if (!isLt5M) {
            this.$message.error('请检查，上传文件大小不能超过5M')
          } else {
            console.log(
              'this.filterConfig',
              this.trackerForm,
              this.uploadFlag,
              this.upId
            )
            fileData.append('file', file.raw)
            fileData.append('uploadFlag', this.uploadFlag)
            fileData.append('fileName', this.trackerForm.fileName)
            fileData.append('rfDate', this.trackerForm.rfDate + '-1')
            fileData.append('solutionHub', this.trackerForm.solutionHub)
            if (this.upId) fileData.append('id', this.upId)

            // fileData.append('tableLikeId', this.filterConfig.tableLikeId)
            this.loadProgress = 0
            this.progressFlag = true
            // this.source = axios.CancelToken.source()
            const uploadEvent = (progressEvent) => {
              this.loadProgress = Number(
                ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
              )
            }

            let that = this
            // 上传
            axios({
              url:
                process.env['VUE_APP_BASE_API'] +
                '/api/iris/pctrackerMvp/importExcel',
              method: 'post',
              headers: { 'Content-Type': 'multipart/form-data' },
              data: fileData,
              onUploadProgress: function (progressEvent) {
                //原生获取上传进度的事件
                that.loadProgress =
                  (progressEvent.loaded / progressEvent.total) * 100 //实时获取上传进度
              }
            }).then((res) => {
              console.log('res', res)
              this.progressFlag = false
              if (res.code === 200) {
                this.flag = res.data.flag;
                this.op111Length = res.data.op111Length
                this.op111List = res.data.op111List
                this.op111WrongLength = res.data.op111WrongLength

                this.op151Length = res.data.op151Length
                this.op151List = res.data.op151List
                this.op151WrongLength = res.data.op151WrongLength

                this.op152Length = res.data.op152Length
                this.op152List = res.data.op152List
                this.op152WrongLength = res.data.op152WrongLength

                this.pcPlugLength = res.data.pcPlugLength
                this.pcPlugList = res.data.pcPlugList
                this.pcPlugWrongLength = res.data.pcPlugWrongLength

                this.upFileName = res.data.fileName
                if (!this.uploadFlag) this.upId = res.data.id
                console.log('this.upId', this.upId)
                // 暂存文件，下一步用;
                // this.fileSaveInfo = file
                this.nextStatus = false

                this.upError = false
                this.upSuccess = true

                // new修改上传逻辑后
                this.active = 1
              } else {
                this.upSuccess = false
                this.upError = true
                this.upErrorMsg = res.msg
              }
            })

            // }
          }
        }
      })
    },
    //处理单元格样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row)
      if (this.fileInfo.fileName.includes('HC by Location')) {
        if (
          [
            '本次RF',
            'HC by Location',
            'January',
            'February',
            'March',
            'April',
            'Total',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ].includes(column.label)
        ) {
          return 'table-header'
        }
      }
      return ''
    },
    deleteFiles() {
      this.loadProgress = 0
      this.fileInfo = {
        fileName: '',
        columns: '',
        rows: ''
      }
      this.reportImportId = ''
      this.tableData = []
      this.upSuccess = false
      this.upError = false
    },
    clearFiles() {
      this.loadProgress = 0
      this.fileInfo = {
        fileName: '',
        columns: '',
        rows: ''
      }
      this.reportImportId = ''
      this.tableData = []
      this.upSuccess = false
      this.upError = false
      // this.source.cancel('取消上传')
      this.$refs.uploadBox.clearFiles()
    }
  }
}
</script>
<style>
.trRed .cell {
  color: red !important;
}
</style>
<style lang="scss" scoped>
.flagColor {
  color: red !important;
}
.fileName {
  background-color: #f4f4f4;
  color: #bb9667;
  font-size: 14px;
  padding: 3px 6px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  .img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
  }
}
.fileNameRed {
  color: #de3934;
}
.uptit {
  color: #de3934;
  font-size: 12px;
  margin-top: 10px;
}
.topTitle {
  font-size: 16px;
  color: #303133;
  font-weight: 500;
  margin-top: 20px;
}
.el-tabs {
  margin-top: 20px;
}
.fileUpComplexPage {
  .el-button {
    padding: 0 20px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  ::v-deep .el-table__cell {
    border-bottom: 0;
    border-right: 0;
  }
  ::v-deep .table-header {
    border-right: 4px solid #ffffff;
  }
  .uploadBox {
    margin-top: 20px;
  }
  .el-step__icon {
    background: #f4f4f4;
  }
  ::v-deep .el-steps {
    .el-step__icon.is-text {
      border: none !important;
      background: #f4f4f4;
      color: #303133;
      i {
        font-family: Roboto-Medium, Roboto;
        font-style: normal;
        font-weight: 500;
      }
    }
    .el-step__title {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
    }
    .el-step__head.is-process {
      border-color: #ebeef5;
      .el-step__icon {
        background-color: #2490e3;
        color: #fff;
      }
    }
    .el-step__head.is-finish {
      border-color: #ebeef5;
      .el-step__icon {
        background-color: #6ebaaf;
        color: #fff;
      }
    }
    .el-step__title.is-process {
      color: #303133;
    }
    .el-step__title.is-finish {
      color: #303133;
    }
    .upError {
      .el-step__head.is-process {
        border-color: #ebeef5;
        .el-step__icon {
          background-color: #de3934;
          color: #fff;
        }
      }
    }
    .xtk {
      .el-step__head {
        border-color: #ebeef5;
        .el-step__icon {
          background-color: #6ebaaf;
          color: #fff;
        }
      }
    }
  }

  ::v-deep .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
    }
  }
  .upload_tip {
    margin-top: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #c4c8cf;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .upload_text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #545454;
    margin-top: 12px;
  }
  .progress {
    margin-top: 32px;
  }
  .closeUp {
    width: 16px;
    height: 16px;
  }
  .deleteUp {
    width: 16px;
    height: 16px;
  }
  .progress div:first-child {
    margin-bottom: 8px;
  }
  .fileInfo {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #909399;
    line-height: 17px;
    margin: 18px 0 13px 0;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #545454;
      margin: 0 24px 0 12px;
    }
  }
  .fileNameBox {
    vertical-align: middle;
    box-sizing: border-box;
    display: block;
    span {
      display: inline-block;
      box-sizing: border-box;
      vertical-align: middle;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    img {
      margin-left: 8px;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: middle;
    }
    .upError {
      font-size: 14px;
      font-weight: 400;
      color: #de3934;
      margin-left: 8px;
    }
  }
  .upSuccessBox {
    margin: 20px auto 0 auto;
    width: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    img {
      width: 70px;
      height: 70px;
      margin-bottom: 21px;
      margin-top: 56px;
    }
    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      margin-bottom: 60px;
    }
    div {
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #323435;
      margin-bottom: 4px;
    }
  }
}
</style>
